/* eslint-disable react-hooks/rules-of-hooks */
import {
  loginWithCode,
  refreshCode,
  UserAccountContext,
  UserAccountModal,
} from '@finn/ua-auth';
import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import {
  FILTERS_RESPONSE_SWR_FALLBACK_KEY,
  getFilters,
} from '@finn/ua-vehicle';
import { CookieKeys, getLocaleFromContext, useSession } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import isArray from 'lodash/isArray';
import { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { ReactNode, useContext, useEffect, useMemo } from 'react';
import { SWRConfig } from 'swr';

import { BasePageData } from '~/types/cosmicModules';
import { Review } from '~/types/reviews';
import PageBodyBuilder from '~/ui-modules/PageBodyBuilder';
import { addSentryRenderPageBreadcrumb } from '~/utils/sentry';

import { Brand } from '../contexts/Vehicle';
import { PathFilterProvider } from '../modules/products/filters';
import { AvailableFilters } from '../types/filter';
import { ConditionalWrapper } from '../utils/general';
import { getLandingPageGetServerSideProps } from '../utils/landingPage';

export interface IProps {
  pageData: BasePageData;
  reviews: Review[];
  brands: Brand[];
  renderMetaContent?: () => ReactNode;
  allAvailableFilters?: AvailableFilters;
}

const HomePage: NextPage<IProps> = ({
  pageData,
  reviews,
  brands,
  renderMetaContent,
  allAvailableFilters,
}) => {
  if (!pageData?.metadata?.modules) return null;

  const router = useRouter();
  const [session] = useSession();
  const { setModalStatus } = useContext(UserAccountContext);

  const removeUrlQuery = () => {
    router.replace(`/`, undefined, {
      shallow: true,
    });
  };

  useEffect(() => {
    const login = async (code: string) => {
      await loginWithCode(code);
      await refreshCode();
    };

    const verificationCode = Cookies.get(CookieKeys.AUTH_EMAIL_VERIFIED);
    if (verificationCode) {
      login(verificationCode);
      setModalStatus({
        isModalOpen: true,
        modalType: UserAccountModal.EMAIL_VERIFIED_SUCCESSFULLY,
      });
      Cookies.remove(CookieKeys.AUTH_EMAIL_VERIFIED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  useEffect(() => {
    const gotoNext = (path: string | string[] | undefined) => {
      const nextPath = isArray(path) ? path[0] : path;
      if (nextPath) {
        const fullUrl = nextPath?.startsWith('/portal')
          ? `https://${window.location.host}/${nextPath}`
          : `https://${window.location.host}/${router.locale}/${nextPath}`;
        window.location.replace(fullUrl);
      } else {
        router.replace('/');
      }
    };
    const { login, next, modal } = router.query;
    if (login === 'true' && session?.user?.email_verified) {
      gotoNext(next);
    }

    if (modal) {
      setModalStatus({
        isModalOpen: true,
        modalType: modal as UserAccountModal,
        options: {
          onClose: () => {
            // To prevent modal from reshowing on page refresh
            removeUrlQuery();
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, session?.user?.email_verified]);

  addSentryRenderPageBreadcrumb('/pages/index', 'Index');

  const swrConfigValue = useMemo(() => {
    return {
      fallback: {
        [FILTERS_RESPONSE_SWR_FALLBACK_KEY]: allAvailableFilters,
      },
    };
  }, [allAvailableFilters]);

  const isExpHPBrandModelHeroFilters = useIsABVariant(
    Features.ExpHPBrandModelHeroFilters
  );

  return (
    <ConditionalWrapper
      condition={isExpHPBrandModelHeroFilters}
      wrapper={(wrapperChildren: React.ComponentType) => (
        <SWRConfig value={swrConfigValue}>
          <PathFilterProvider basePathname={BASE_SUBSCRIPTION_PATH}>
            {wrapperChildren}
          </PathFilterProvider>
        </SWRConfig>
      )}
    >
      <PageBodyBuilder
        data={pageData}
        reviews={reviews}
        brands={brands}
        renderMetaContent={renderMetaContent}
      />
    </ConditionalWrapper>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const locale = getLocaleFromContext(ctx);
  const slug = 'home-new';

  const props = await getLandingPageGetServerSideProps(slug, locale);

  const filtersResponse = await getFilters({
    filters: {},
    locale,
  });

  return {
    props: {
      pageData: props.pageData,
      brands: props.brands,
      reviews: props.reviews,
      allAvailableFilters: filtersResponse,
    },
  };
};

export default HomePage;
